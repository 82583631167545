import React, { Component } from 'react' ;

class test_area extends Component {
    render () {
        return(
        <div className="flex-layout-section-full" style={{height:'1000px'}}>
            <div className="flex-layout-column-full">
                <p className="choo">peepoo agree or disagree<br/><br/>
            </p>
              </div>
              <div>
               <p className="chooch">here some choccy milk becuz your ebic</p>   
              </div>
              <div className="flex-layout-justified-sb"> 
              <div>
               <p className="content-text-48 margin-top-75per choo">these emjois r ebic!</p>    
                   </div>
              <div>
              <img className="flex-image-container image-size-30"   alt=" User Interface Design" />  
              </div>
              <div>
              <img className="flex-image-container image-size-30"  alt=" User Interface Design" />  
              </div>
               
              <div>
              <img className="flex-image-container image-size-30"  alt=" User Interface Design" />  
              </div>
               <div>
              <img className="flex-image-container image-size-50"  alt=" User Interface Design" /> 
               </div>
              
               <div>
              <img className="flex-image-container image-size-50"   alt=" User Interface Design" />
              </div>
              <div>
              <img className="flex-image-container image-size-100"  alt=" User Interface Design" />  
              </div>
              </div>
              <div>
              <img className="flex-image-container image-size-100"  alt=" User Interface Design" />  
              </div>
  

              <div>
               <p className="woo">i'm the ed sheeran of lacrosse  
              
              <div>

              </div></p> 
            </div>
                 
        </div>  
                                     
                            )
                        }
                    }
export default test_area;